import React from 'react';
import ReactExport from 'react-data-export';
import PropTypes from 'prop-types';
import moment from 'moment';
import { capital } from 'case';

import { locale } from '../../../helpers/locale';
import { withNationHOC } from '../../../hocs/withNation';
const { ExcelFile } = ReactExport;
const { ExcelSheet, ExcelColumn } = ExcelFile;

import { ExcelStatisticsWrapper } from 'containers';

export const joinVisits = (visits, visitors, nation) => {
  return visits.map(visit => {
    let visitor;
    for (let i = 0; i < visitors.length; i++) {
      if (visitors[i].timeframe.start === visit.timeframe.start) {
        visitor = visitors[i].value;
        break;
      }
      visitor = 0;
    }
    return {
      visits: visit.value,
      visitors: visitor,
      timeframe: moment(visit.timeframe.start).format(locale.date[nation])
    };
  });
};

const joinEmployerAnalytics = postingAnalyticsData => {
  const { byApplicants, byPostings } = postingAnalyticsData.employers;
  const newDataObject = {};
  for (let index = 0; index < byApplicants.length; index++) {
    const { employerId, employerName, result } = byApplicants[index];
    newDataObject[employerId] = {
      employerId,
      employerName,
      applicants: result,
      postings: 0
    };
  }

  for (let index = 0; index < byPostings.length; index++) {
    const { employerId, employerName, result } = byPostings[index];

    if (!newDataObject[employerId]) {
      newDataObject[employerId] = {
        employerId,
        employerName,
        applicants: 0,
        postings: result
      };
    } else {
      newDataObject[employerId].postings = result;
    }
  }
  const newDataArray = Object.values(newDataObject).sort((a, b) =>
    a.postings > b.postings ? -1 : 1
  );
  return newDataArray;
};

const joinPostingAnalytics = (byApplicants, byViews) => {
  const newDataObject = {};
  for (let index = 0; index < byViews.length; index++) {
    const posting = byViews[index];
    const postingId = posting['posting.postingId'];
    newDataObject[postingId] = {
      postingId: postingId,
      postingName: posting['posting.title'],
      applicants: 0,
      views: posting.result
    };
  }

  for (let index = 0; index < byApplicants.length; index++) {
    const posting = byApplicants[index];
    const postingId = posting['posting-id'];
    if (!newDataObject[postingId]) {
      newDataObject[postingId] = {
        postingId: posting['posting-id'],
        postingName: posting.name,
        views: 0,
        applicants: posting.result
      };
    } else {
      newDataObject[postingId].applicants = posting.result;
    }
  }
  const newDataArray = Object.values(newDataObject).sort((a, b) => (a.views > b.views ? -1 : 1));
  return newDataArray;
};

const ExcelStatisticsWorkbook = withNationHOC(
  ({
    data,
    employerPortal,
    hasJobPostings,
    requestEmail,
    hasPrograms,
    nation,
    postingAnalyticsData,
    resumeBuilder
  }) => {
    const introduction = [
      {
        text: `The Career Coach Analytics workbook is comprised of up to 18 worksheets (not including the introduction worksheet). The number of worksheets varies depending on site configurations. The worksheets and descriptions of each are as follows: "Total Visits and Visitors" shows the total number of visits and visitors to Career Coach during the selected period. "Visits and Visitors" shows the number of unique daily visits and unique daily visitors for each day within the selected time period. "Top Viewed Careers" lists careers in descending order of the number of visits to that career\'s page. "Top Viewed ${
          locale.edTrack[nation]
        }s" lists ${locale.edTrack[
          nation
        ].toLowerCase()}s in descending order of the number of visits to that ${locale.edTrack[
          nation
        ].toLowerCase()}\'s page. "Top ${locale.spellings.favorite[nation]}d Careers" and "Top ${
          locale.spellings.favorite[nation]
        }d ${locale.edTrack[nation]}s" list each career and ${locale.edTrack[
          nation
        ].toLowerCase()}s, respectively, and the number of times each has been ${
          locale.spellings.favorite[nation]
        }d by users during the selected time period. "Top Info Requests by ${
          locale.edTrack[nation]
        }" lists the number of requests for information about a ${
          locale.edTrack[nation]
        } generated from that ${
          locale.edTrack[nation]
        } page. "Top Cities" lists the number of visits originating from each city (based on IP address).  "Top ${
          locale.location.code[nation]
        } Codes" lists the number of visits originating from each ${
          locale.location.code[nation]
        } Code (based on IP address). "Assessments" shows the number of assessments started and completed in separate columns. "${
          locale.resume[nation]
        }s" shows the number of ${
          locale.resume.lower[nation]
        }s started and downloaded in separate columns. "Top Career Searches" and "Top ${
          locale.edTrack[nation]
        } Searches" list each search term and the number of times it has been searched during the selected time period. "Top Careers by Job Posting" lists careers in descending order of clicks generated by job posting links found on that career\'s page. "Top Referrals" lists external links to Career Coach in descending order of the number visits generated. "${
          locale.edTrack[nation]
        } Link Clicks" shows the number of links the client\'s ${locale.edTrack[
          nation
        ].toLowerCase()} page generated by the link on the corresponding Career Coach ${
          locale.edTrack[nation]
        } page. "Tuition Link Clicks" shows the number of links the client\'s tuition information page generated by the link on the corresponding Career Coach ${
          locale.edTrack[nation]
        } page. "Admission Link Clicks" shows the number of links the client\'s admission page generated by the link on the corresponding Career Coach ${
          locale.edTrack[nation]
        } page. "Top Employers" shows the number of postings and applicants for employers who use the client's Employer Portal.
        "Top Postings" shows the number of views and applicants for postings on the client's Employer Portal`,
        value: null
      }
    ];

    const extractCareerTitle = pageUrlData =>
      pageUrlData.map(event => ({
        // extracts career titleSlug from page_url, then decamelizes and capitalizes
        result: event.result,
        career: capital(event.page_url.split('/')[4])
      }));

    const incorrectlyCalculateTotalVisitsOrVisitors = key => {
      // adds up the daily unique visits to get the total for the time period. This is wrong but so is the profiles table, so it matches.
      let total = data[key][0].value;
      for (let i = 1; i < data[key].length; i++) {
        total += data[key][i].value;
      }
      return total;
    };

    return (
      <ExcelStatisticsWrapper
        filename="Career_Coach_Analytics"
        employerPortal={employerPortal}
        hasJobPostings={hasJobPostings}
        hasPrograms={hasPrograms}
        resumeBuilder={resumeBuilder}
        requestEmail={requestEmail}
      >
        <ExcelSheet
          data={[
            {
              visits: incorrectlyCalculateTotalVisitsOrVisitors('visits'),
              visitors: incorrectlyCalculateTotalVisitsOrVisitors('visitors')
            }
          ]}
          name="Total Visits and Visitors"
        >
          <ExcelColumn label="Total Visits" value="visits" />
          <ExcelColumn label="Total Visitors" value="visitors" />
          <ExcelColumn value="footnote" />
        </ExcelSheet>

        <ExcelSheet
          data={joinVisits(data['visits'], data['visitors'], nation)}
          name="Visits and Visitors"
        >
          <ExcelColumn label="Day" value="timeframe" />
          <ExcelColumn label="Visits" value="visits" />
          <ExcelColumn label="Visitors" value="visitors" />
        </ExcelSheet>

        <ExcelSheet data={extractCareerTitle(data['top-careers'])} name="Top Viewed Careers">
          <ExcelColumn label="Career" value="career" />
          <ExcelColumn label="Views" value="result" />
        </ExcelSheet>

        <ExcelSheet data={data['top-programs']} name={`Top Viewed ${locale.edTrack[nation]}s`}>
          <ExcelColumn label="Title" value="program.name" />
          <ExcelColumn label="Credential" value="program.credential" />
          <ExcelColumn label="Views" value="result" />
        </ExcelSheet>

        <ExcelSheet
          data={data['top-career-favorites']}
          name={`Top ${locale.spellings.favorite[nation]}d Careers`}
        >
          <ExcelColumn label="Title" value="name" />
          <ExcelColumn label={`${locale.spellings.favorite[nation]}s`} value="result" />
        </ExcelSheet>

        <ExcelSheet
          data={data['top-program-favorites']}
          name={`Top ${locale.spellings.favorite[nation]}d ${locale.edTrack[nation]}s`}
        >
          <ExcelColumn label="Title" value="name" />
          <ExcelColumn label={`${locale.spellings.favorite[nation]}s`} value="result" />
        </ExcelSheet>

        <ExcelSheet
          data={data['top-info-requests']}
          name={`Top Info Requests by ${locale.edTrack[nation]}`}
        >
          <ExcelColumn label="Title" value="program.name" />
          <ExcelColumn label="Credential" value="program.credential" />
          <ExcelColumn label="Requests" value="result" />
        </ExcelSheet>

        <ExcelSheet data={data['top-cities']} name="Top Cities">
          <ExcelColumn label="City" value="ip_geo_info.city" />
          <ExcelColumn
            label={nation === 'us' ? 'State' : 'Province'}
            value="ip_geo_info.province"
          />
          <ExcelColumn label="Views" value="result" />
        </ExcelSheet>

        <ExcelSheet data={data['top-zips']} name={`Top ${locale.location.code[nation]} Codes`}>
          <ExcelColumn
            label={`${locale.location.code[nation]} Codes`}
            value="ip_geo_info.postal_code"
          />
          <ExcelColumn label="Views" value="result" />
        </ExcelSheet>

        <ExcelSheet
          data={[
            {
              starts: data['total-assessment-starts'],
              completions: data['total-assessment-completions']
            }
          ]}
          name="Assessments"
        >
          <ExcelColumn label="Starts" value="starts" />
          <ExcelColumn label="Completions" value="completions" />
        </ExcelSheet>

        <ExcelSheet
          data={[
            {
              starts: data['resume-starts'],
              downloads: data['resume-downloads']
            }
          ]}
          name={`${locale.resume[nation]}s`}
        >
          <ExcelColumn label="Starts" value="starts" />
          <ExcelColumn label="Downloads" value="downloads" />
        </ExcelSheet>

        <ExcelSheet data={data['top-career-searches']} name="Top Career Searches">
          <ExcelColumn label="Search Terms" value="searchTerm" />
          <ExcelColumn label="Searches" value="result" />
        </ExcelSheet>

        <ExcelSheet
          data={data['top-program-searches']}
          name={`Top ${locale.edTrack[nation]} Searches`}
        >
          <ExcelColumn label="Search Terms" value="searchTerm" />
          <ExcelColumn label="Searches" value="result" />
        </ExcelSheet>

        <ExcelSheet
          data={extractCareerTitle(data['job-posting'])}
          name="Top Careers by Job Postings"
        >
          <ExcelColumn label="Career" value="career" />
          <ExcelColumn label="Job Postings Clicks" value="result" />
        </ExcelSheet>

        <ExcelSheet data={data['referrer-urls']} name="Top Referrals">
          <ExcelColumn label="URL" value="referrer_url" />
          <ExcelColumn label="Referrals" value="result" />
        </ExcelSheet>

        <ExcelSheet data={data['program-clicks']} name={`${locale.edTrack[nation]} Link Clicks`}>
          <ExcelColumn label="Name" value="program.name" />
          <ExcelColumn label="Credential" value="program.credential" />
          <ExcelColumn label="Clicks" value="result" />
        </ExcelSheet>

        <ExcelSheet data={data['tuition-clicks']} name="Tuition Link Clicks">
          <ExcelColumn label="Name" value="program.name" />
          <ExcelColumn label="Credential" value="program.credential" />
          <ExcelColumn label="Clicks" value="result" />
        </ExcelSheet>

        <ExcelSheet data={data['admission-clicks']} name="Admission Link Clicks">
          <ExcelColumn label="Name" value="program.name" />
          <ExcelColumn label="Credential" value="program.credential" />
          <ExcelColumn label="Clicks" value="result" />
        </ExcelSheet>
        <ExcelSheet data={introduction} name="Introduction">
          <ExcelColumn label={`${introduction[0].text}`} value="value" />
        </ExcelSheet>

        <ExcelSheet data={joinEmployerAnalytics(postingAnalyticsData)} name="Top Employers">
          <ExcelColumn label="Employer" value="employerName" />
          <ExcelColumn label="Postings" value="postings" />
          <ExcelColumn label="Applicants" value="applicants" />
        </ExcelSheet>

        <ExcelSheet
          data={joinPostingAnalytics(
            postingAnalyticsData.topPostingsByApplicants,
            data['work-opportunity-views-by-posting']
          )}
          name="Top Postings"
        >
          <ExcelColumn label="Posting" value="postingName" />
          <ExcelColumn label="Views" value="views" />
          <ExcelColumn label="Applicants" value="applicants" />
        </ExcelSheet>
      </ExcelStatisticsWrapper>
    );
  }
);

ExcelStatisticsWorkbook.propTypes = {
  data: PropTypes.object,
  employerPortal: PropTypes.bool,
  hasJobPostings: PropTypes.bool,
  hasPrograms: PropTypes.bool,
  requestEmail: PropTypes.string,
  resumeBuilder: PropTypes.bool
};

export default ExcelStatisticsWorkbook;
