export const keenAdmissionClicks = {
  key: 'admission-clicks',
  event: 'Program Admissions Clicks',
  resultKey: 'program.name',
  groupBy: ['program.name', 'program.credential', 'program.slug', 'program.ipeds']
};

export const keenAssessmentCompletions = {
  key: 'assessment-completions',
  event: 'Assessment Completions',
  interval: 'daily'
};

export const keenAssessmentStarts = {
  key: 'assessment-starts',
  event: 'Assessment Starts',
  interval: 'daily'
};

export const keenAssessmentCompletionsBySchool = {
  key: 'assessment-completions-by-school',
  event: 'Assessment Completions',
  groupBy: ['schoolName']
};

export const keenAssessmentStartsBySchool = {
  key: 'assessment-starts-by-school',
  event: 'Assessment Starts',
  groupBy: ['schoolName']
};

export const keenProfilesCreated = {
  event: 'Profile Created',
  interval: 'daily'
};

export const keenProgramClicks = {
  key: 'program-clicks',
  event: 'School Program Clicks',
  resultKey: 'program.name',
  groupBy: ['program.name', 'program.credential', 'program.slug', 'program.ipeds']
};

export const keenReferrerUrls = {
  key: 'referrer-urls',
  event: 'Page Views',
  resultKey: 'referrer_url',
  groupBy: ['referrer_url'],
  queryType: 'count_unique',
  targetProperty: 'visitId',
  ignoreReferrers: ['emsicc', 'lightcastcc']
};

export const keenResumeDownloads = {
  key: 'resume-downloads',
  event: 'Resume Exported'
};

export const keenResumeStarts = {
  key: 'resume-starts',
  event: 'Resume Started'
};

export const keenSkillsAdded = {
  key: 'skills-added',
  event: 'Skills Assessment Skills Added',
  queryType: 'count',
  interval: 'daily'
};

export const keenSkillListStarts = {
  key: 'skill-list-starts',
  event: 'Skills Assessment Skills Added',
  queryType: 'count_unique',
  targetProperty: 'visitorId',
  interval: 'daily'
};

export const keenResultsPageVisits = {
  key: 'skill-results-page-visits',
  event: 'Page Views',
  queryType: 'count_unique',
  targetProperty: 'visitorId',
  interval: 'daily',
  extraFilters: [
    {
      operator: 'contains',
      property_name: 'page_url',
      property_value: 'skills-match/assessment/results'
    }
  ]
};

export const keenEducationSkillsAdded = {
  key: 'education-skills-added',
  event: 'Skills Assessment Skills Added',
  queryType: 'count',
  groupBy: ['label'],
  extraFilters: [
    {
      operator: 'eq',
      property_name: 'origin',
      property_value: 'education'
    }
  ]
};

export const keenWorkExperienceSkillsAdded = {
  key: 'education-work-experience-added',
  event: 'Skills Assessment Skills Added',
  queryType: 'count',
  groupBy: ['label'],
  extraFilters: [
    {
      operator: 'eq',
      property_name: 'origin',
      property_value: 'work-experience'
    }
  ]
};

export const keenAdditionalSkillsAdded = {
  key: 'education-work-experience-added',
  event: 'Skills Assessment Skills Added',
  queryType: 'count',
  groupBy: ['label'],
  extraFilters: [
    {
      operator: 'eq',
      property_name: 'origin',
      property_value: 'additional'
    }
  ]
};

export const keenTopCareers = {
  key: 'top-careers',
  event: 'Page Views',
  resultKey: 'page_url',
  groupBy: ['page_url'],
  extraFilters: [
    {
      operator: 'contains',
      property_name: 'page_url',
      property_value: '/careers/'
    },
    {
      operator: 'not_contains',
      property_name: 'page_url',
      property_value: '/about'
    },
    {
      operator: 'not_contains',
      property_name: 'page_url',
      property_value: '/wages'
    },
    {
      operator: 'not_contains',
      property_name: 'page_url',
      property_value: '/employment'
    },
    {
      operator: 'not_contains',
      property_name: 'page_url',
      property_value: '/jobs'
    }
  ]
};

export const keenTopCareerFavorites = {
  key: 'top-career-favorites',
  event: 'Favorites',
  resultKey: 'name',
  groupBy: ['name', 'id'],
  extraFilters: [
    {
      operator: 'eq',
      property_name: 'type',
      property_value: 'careers'
    },
    {
      operator: 'exists',
      property_name: 'id',
      property_value: true
    }
  ]
};

export const keenTopCareersByJobPosting = {
  key: 'job-posting',
  event: 'Indeed Posting Clicks',
  resultKey: 'page_url',
  groupBy: ['page_url']
};

export const keenTopCareerSearches = {
  key: 'top-career-searches',
  event: 'Top Searches',
  resultKey: 'searchTerm',
  groupBy: ['searchTerm'],
  extraFilters: {
    operator: 'in',
    property_name: 'searchType',
    property_value: ['Careers', 'careers']
  }
};

export const keenTopCities = {
  key: 'top-cities',
  queryType: 'count_unique',
  event: 'Page Views',
  targetProperty: 'visitId',
  resultKey: 'ip_geo_info.city',
  groupBy: ['ip_geo_info.city', 'ip_geo_info.province', 'ip_geo_info.country']
};

export const keenTopInfoRequests = {
  key: 'top-info-requests',
  event: 'Request Information',
  resultKey: 'program.name',
  groupBy: ['program.name', 'program.credential', 'program.slug', 'program.ipeds']
};

export const keenTopProgramFavorites = {
  key: 'top-program-favorites',
  event: 'Favorites',
  resultKey: 'name',
  groupBy: ['name', 'id'],
  extraFilters: [
    {
      operator: 'eq',
      property_name: 'type',
      property_value: 'programs'
    }
  ]
};

export const keenTopPrograms = {
  key: 'top-programs',
  event: 'Program View',
  resultKey: 'program.name',
  groupBy: ['program.name', 'program.credential', 'program.slug', 'program.ipeds']
};

export const keenTopProgramSearches = {
  key: 'top-program-searches',
  event: 'Top Searches',
  resultKey: 'searchTerm',
  groupBy: ['searchTerm'],

  extraFilters: {
    operator: 'in',
    property_name: 'searchType',
    property_value: ['Courses', 'Programs', 'programs']
  }
};

export const keenTopZips = {
  key: 'top-zips',
  queryType: 'count_unique',
  event: 'Page Views',
  targetProperty: 'visitId',
  resultKey: 'ip_geo_info.postal_code',
  groupBy: ['ip_geo_info.postal_code']
};

export const keenTotalAssessmentCompletions = {
  key: 'total-assessment-completions',
  event: 'Assessment Completions'
};

export const keenTotalAssessmentStarts = {
  key: 'total-assessment-starts',
  event: 'Assessment Starts'
};

export const keenTotalProfilesCreated = {
  event: 'Profile Created'
};

export const keenTotalVisitors = {
  key: 'total-visitors',
  queryType: 'count_unique',
  event: 'Page Views',
  targetProperty: 'visitorId'
};

export const keenTotalVisits = {
  key: 'total-visits',
  queryType: 'count_unique',
  event: 'Page Views',
  targetProperty: 'visitId'
};

export const keenTuitionClicks = {
  key: 'tuition-clicks',
  event: 'Program Tuition Clicks',
  resultKey: 'program.name',
  groupBy: ['program.name', 'program.credential', 'program.slug', 'program.ipeds']
};

export const keenVisitors = {
  key: 'visitors',
  queryType: 'count_unique',
  event: 'Page Views',
  interval: 'daily',
  targetProperty: 'visitorId'
};

export const keenVisits = {
  key: 'visits',
  queryType: 'count_unique',
  event: 'Page Views',
  interval: 'daily',
  targetProperty: 'visitId'
};

export const keenWorkOpportunityViews = {
  key: 'work-opportunity-views',
  queryType: 'count',
  event: 'Page Views',
  interval: 'daily',
  extraFilters: {
    operator: 'contains',
    property_name: 'page_url',
    property_value: 'work-opportunities'
  }
};

export const keenWorkOpportunityUniqueViews = {
  key: 'work-opportunity-unique-views',
  queryType: 'count_unique',
  event: 'Page Views',
  targetProperty: 'visitorId',
  interval: 'daily',
  extraFilters: {
    operator: 'contains',
    property_name: 'page_url',
    property_value: 'work-opportunities'
  }
};

export const keenWorkOpportunityViewsByPosting = {
  key: 'work-opportunity-views-by-posting',
  queryType: 'count',
  event: 'Employer Portal Posting Clicks',
  groupBy: ['posting.title', 'posting.postingId', 'posting.endOn', 'posting.createdOn'],
  resultKey: 'posting.title'
};

// *** widget queries

// program widget

export const keenWidgetTotalLoads = {
  project: 'widget',
  key: 'widget-total-loads',
  queryType: 'count',
  event: 'Loads'
};

export const keenWidgetTotalVisitButtonClicks = {
  project: 'widget',
  key: 'widget-total-visit-button-clicks',
  queryType: 'count',
  event: 'Visit Button Clicks'
};

export const keenWidgetTotalClicks = {
  project: 'widget',
  key: 'widget-total-clicks',
  queryType: 'count',
  event: 'Clicks'
};

export const keenWidgetTotalLoadsByPrograms = {
  project: 'widget',
  key: 'widget-total-loads-by-programs',
  queryType: 'count',
  event: 'Loads',
  groupBy: ['programs']
};

export const keenWidgetTotalVisitButtonClicksByPrograms = {
  project: 'widget',
  key: 'widget-total-visit-button-clicks-by-programs',
  queryType: 'count',
  event: 'Visit Button Clicks',
  groupBy: ['programs']
};

export const keenWidgetTotalClicksByPrograms = {
  project: 'widget',
  key: 'widget-total-clicks-by-programs',
  queryType: 'count',
  event: 'Clicks',
  groupBy: ['programs']
};

export const keenWidgetLoads = {
  project: 'widget',
  key: 'widget-loads',
  queryType: 'count',
  event: 'Loads',
  interval: 'daily'
};

export const keenWidgetClicks = {
  project: 'widget',
  key: 'widget-clicks',
  queryType: 'count',
  event: 'Clicks',
  interval: 'daily'
};

export const keenWidgetVisitButtonClicks = {
  project: 'widget',
  key: 'widget-visit-button-clicks',
  queryType: 'count',
  event: 'Visit Button Clicks',
  interval: 'daily'
};

export const keenWidgetVisitButtonClicksByCareerCoachUrl = {
  project: 'widget',
  key: 'widget-visit-button-clicks-by-career-by-career-coach-url',
  queryType: 'count',
  event: 'Visit Button Clicks',
  groupBy: ['careerCoachUrl']
};

/* assessment widget */

export const keenAssessmentWidgetTotalStarts = {
  project: 'widget',
  key: 'assessment-widget-total-starts',
  queryType: 'count',
  event: 'AW-Assessment Starts'
};

export const keenAssessmentWidgetTotalCompletions = {
  project: 'widget',
  key: 'assessment-widget-total-completions',
  queryType: 'count',
  event: 'AW-Assessment Completions'
};

export const keenAssessmentWidgetTotalProgramLinkClicks = {
  project: 'widget',
  key: 'assessment-widget-total-program-link-clicks',
  queryType: 'count',
  event: 'AW-Program Link Clicks'
};

export const keenAssessmentWidgetStarts = {
  project: 'widget',
  key: 'assessment-widget-starts',
  queryType: 'count',
  event: 'AW-Assessment Starts',
  interval: 'daily'
};

export const keenAssessmentWidgetCompletions = {
  project: 'widget',
  key: 'assessment-widget-completions',
  queryType: 'count',
  event: 'AW-Assessment Completions',
  interval: 'daily'
};

export const keenAssessmentWidgetProgramLinkClicks = {
  project: 'widget',
  key: 'assessment-widget-program-link-clicks',
  queryType: 'count',
  event: 'AW-Program Link Clicks',
  interval: 'daily'
};

export const keenAssessmentWidgetProgramLinkClicksByProgram = {
  project: 'widget',
  key: 'assessment-widget-program-link-clicks-by-program',
  queryType: 'count',
  event: 'AW-Program Link Clicks',
  groupBy: ['programName', 'programCredential', 'programUrl']
};

export const keenAssessmentWidgetCareerLinkClicks = {
  project: 'widget',
  key: 'assessment-widget-career-link-clicks',
  queryType: 'count',
  event: 'AW-Career Link Clicks',
  interval: 'daily'
};

export const keenAssessmentWidgetCareerLinkClicksByCareer = {
  project: 'widget',
  key: 'assessment-widget-career-link-clicks-by-career',
  queryType: 'count',
  event: 'AW-Career Link Clicks',
  groupBy: ['careerTitle', 'careerCode']
};

export const keenAssessmentWidgetDownloads = {
  project: 'widget',
  key: 'assessment-widget-downloads',
  queryType: 'count',
  event: 'AW-Assessment Downloads',
  interval: 'daily'
};
