if (typeof window !== 'undefined' && !window.location.origin) {
  window.location.origin =
    window.location.protocol +
    '//' +
    window.location.hostname +
    (window.location.port ? ':' + window.location.port : '');
}

const baseConfig = {
  adminKeen: {
    projectId: process.env.ADMIN_KEEN_DEV_PROJECT_KEY,
    writeKey: process.env.ADMIN_KEEN_DEV_WRITE_KEY
  },
  algoliaProjectId: process.env.ALGOLIA_PROJECTID,
  algoliaKey: process.env.ALGOLIA_KEY,
  usDataRun: '2024.3',
  caDataRun: '2024.3',
  ukDataRun: '2024.2',
  /* used for images */
  taxonomyVersion: {
    us: 'onet2019',
    ca: 'noc2021',
    uk: 'soc2020',
    lot: '6.0.0'
  },
  awsCognitoAuth: {
    region: 'us-west-2',
    userPoolId: process.env.QA_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.QA_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: 'career-coach-qa.auth.us-west-2.amazoncognito.com', // URL of Career Coach "App Client" configured in AWS Cognito. Used to access Cognito's Oauth API.
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'], // Permission scopes granting access to user data. "aws.cognito.signin.user.admin" is a required scope for returning user attributes due to AWS Amplify needing it.
      redirectSignIn: 'http://localhost:3001', // Default Career Coach Frontend URL to redirect to after a SAML signin request
      redirectSignOut: 'http://localhost:3001', // Default Career Coach Frontend URL to redirect to after a SAML signout request
      responseType: 'token' // Tells Cognito to respond with a JWT token upon success authentication.
    }
  },
  cloudfrontUrl: 'https://d3s4k660pqkq8k.cloudfront.net',

  defaultSite: 'testsite',
  usGisDatarun: '2024.3',
  caGisDatarun: 2021,
  ukGisDatarun: 2015,
  internalIPs: '64.126.168.95,162.142.27.1,162.142.27.2,162.142.27.3,162.142.27.4,162.142.27.5,162.142.27.6,162.142.27.7,162.142.27.8,162.142.27.9,162.142.27.10,162.142.27.11,162.142.27.12,162.142.27.13,162.142.27.14,162.142.27.33,162.142.27.34,162.142.27.35,162.142.27.36,162.142.27.37,162.142.27.38,162.142.27.39,162.142.27.40,162.142.27.41,162.142.27.42,162.142.27.43,162.142.27.44,162.142.27.45,162.142.27.46,162.142.27.47,162.142.27.48,162.142.27.49,162.142.27.50,162.142.27.51,162.142.27.52,162.142.27.53,162.142.27.54,162.142.27.55,162.142.27.56,162.142.27.57,162.142.27.58,162.142.27.59,162.142.27.60,162.142.27.61,162.142.27.62'.split(
    ','
  ),
  mandrillKey: process.env.MANDRILL_KEY,
  mandrillUrl: process.env.MANDRILL_URL,
  widgetUrl: 'https://widget.lightcastcc-qa.com'
};

export default baseConfig;
