import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { LineChart, Loading } from 'components';
import { Total, TotalLabel, TotalRow, TotalWrapper } from '../PercentageChart/components';

const TotalChart = ({ color, chartId, data, a11yHeader, isLoaded, nation }) => {
  const [totals, setTotals] = useState([]);

  useEffect(() => {
    if (isLoaded) {
      const newTotals = [];
      for (let index = 0; index < data.length; index++) {
        const currentData = data[index];
        const newTotal = currentData.results
          .map(({ value }) => value)
          .reduce((alpha, bravo) => alpha + bravo);
        newTotals.push(newTotal);
      }
      setTotals(newTotals);
    }
  }, [isLoaded]);

  return (
    <Loading loaded={isLoaded}>
      {isLoaded && (
        <Fragment>
          <TotalRow>
            {totals.map((currentTotal, index) => (
              <TotalWrapper key={`${chartId}-total=${index}`}>
                <Total color={data[index].color}>{currentTotal}</Total>
                <TotalLabel>{`Total ${data[index].label}`}</TotalLabel>
              </TotalWrapper>
            ))}
          </TotalRow>

          {totals.length > 0 && (
            <LineChart
              a11yHeader={a11yHeader}
              color={color}
              chartId={chartId}
              data={data}
              nation={nation}
            />
          )}
        </Fragment>
      )}
    </Loading>
  );
};

TotalChart.propTypes = {
  a11yHeader: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  data: PropTypes.array,
  chartId: PropTypes.string,
  heading: PropTypes.string,
  isLoaded: PropTypes.bool,
  nation: PropTypes.string.isRequired,
  percentageLabel: PropTypes.string
};

export default TotalChart;
